<template>
	<Component
		:is="component"
		class="default-font"
		:class="{
			'property-card': !isFullsizeVariant && !isMinisiteVariant,
			'fullsize-card': isFullsizeVariant,
			'minisite-card': isMinisiteVariant
		}"
		:data-variant="variant"
		v-bind="cardProps"
		@click.stop.prevent="emit('view-property', property, 'card-body')"
	>
		<div class="thumbnail-overlay"></div>
		<div v-if="!isFullsizeVariant && !isMinisiteVariant" class="card-thumbnail">
			<div class="tag-wrapper">
				<div
					v-for="tag in tags"
					:key="`property-card-tag:${property.id}:${tag}`"
					class="tag default-font"
					:data-rounded="styles.appearance.defaultBorderRadius"
				>
					{{ tag }}
				</div>
			</div>
			<NuxtImg
				:src="thumbnail.watermarkUrl"
				:alt="thumbnail.caption || 'Imagem do imóvel'"
				width="500px"
				:sizes="propertyImageSizes"
				:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
				loading="lazy"
			/>
			<ButtonBlock
				v-if="variant === 'campaign'"
				class="campaign-modal-button"
				text="Entrar em contato"
				background-color="black"
				text-color="white"
				gap="0.5rem"
				@click.stop.prevent="emit('open-campaign-modal')"
			>
				<template #after>
					<LetterIcon />
				</template>
			</ButtonBlock>
		</div>

		<div v-if="isFullsizeVariant || isMinisiteVariant" class="tag-wrapper">
			<div
				v-for="tag in tags"
				:key="`property-card-tag:${property.id}:${tag}`"
				class="tag default-font"
			>
				{{ tag }}
			</div>
		</div>

		<div class="card-content">
			<div class="card-heading">
				<div class="type-and-address">
					<span
						v-if="!isFullsizeVariant"
						class="default-font property-type-text"
					>
						{{ property.propertyType.name }}
					</span>
					<ChevronRightIcon v-if="isMinisiteVariant" class="separator-icon" />
					<span class="address-text">
						{{ propertyAddress }}
					</span>
				</div>
				<div v-if="isMinisiteVariant" class="pricing">
					<span
						v-for="(price, index) in pricing"
						:key="`property-card-pricing:${property.id}:${price}`"
						class="default-font"
						:class="{
							'primary-price': index === 0,
							'secondary-price': index === 1
						}"
					>
						{{ price }}
					</span>
				</div>
				<span v-if="!isFullsizeVariant" class="default-font commercial-id-text">
					{{ property.commercialId }}
				</span>
			</div>
			<div v-if="items.length > 0" class="property-rooms">
				<div v-for="item in items" :key="item.label">
					<div class="item">
						<Component :is="item.icon" v-if="!isKollerVariant" />
						<span class="item-text">
							<template
								v-if="isFullsizeVariant || isKollerVariant || isMinisiteVariant"
							>
								{{ item.value }} {{ item.label }}
							</template>
							<template v-else>
								{{ item.value }}
							</template>
						</span>
					</div>
				</div>
			</div>
			<div
				v-if="isMinisiteVariant"
				class="property-contact-and-media-container"
			>
				<div class="buttons-wrapper">
					<div class="buttons-container">
						<template v-if="acceptPartnershipRequestUrl">
							<AcceptPartnershipButton
								source="property-card"
								:accept-request-url="acceptPartnershipRequestUrl"
								color="white"
								only-icon-on-mobile
								@click.stop
							/>
						</template>

						<template v-else>
							<ButtonBlock
								v-if="shouldShowGetInTouchButton"
								v-bind="getInTouchButtonConfig"
								class="property-contact-button"
								background-color="white"
								text-color="black"
								:data-rounded="styles.appearance.defaultBorderRadius"
								@click.stop.prevent="emit('send-get-in-touch-message')"
							>
								<template #before>
									<WhatsAppIcon />
									<span class="button-text default-font">
										Falar com o corretor
									</span>
								</template>
							</ButtonBlock>
							<ButtonBlock
								v-if="showScheduleVisitButtons"
								v-bind="scheduleVisitButtonConfig"
								class="property-contact-button"
								background-color="white"
								text-color="black"
								:data-rounded="styles.appearance.defaultBorderRadius"
								@click.stop.prevent="emit('open-schedule-property-visit-modal')"
							>
								<template #before>
									<CalendarIcon />
									<span class="button-text default-font">Agendar visita</span>
								</template>
							</ButtonBlock>
						</template>
					</div>
					<div class="buttons-container">
						<button
							class="view-property-info"
							data-rounded="pill"
							@click.stop.prevent="emit('open-gallery')"
						>
							<GalleryIcon class="view-property-button-icon" />
							<div class="view-property-info-text">
								<span class="default-font">Fotos</span>
							</div>
						</button>
						<button
							class="view-property-info expand-property-button"
							data-rounded="pill"
							@click.stop.prevent="emit('view-property', property, 'button')"
						>
							<ExpandIcon class="view-property-button-icon" />
							<div class="view-property-info-text">
								<span class="default-font">Mostrar mais</span>
							</div>
						</button>
					</div>
				</div>
			</div>

			<div v-if="!isMinisiteVariant" class="pricing">
				<span
					v-for="(price, index) in pricing"
					:key="`property-card-pricing:${property.id}:${index}`"
					class="default-font"
					:class="{
						'primary-price': index === 0,
						'secondary-price': index === 1
					}"
				>
					{{ price }}
				</span>
			</div>
		</div>
	</Component>
</template>

<script setup lang="ts">
import type { PropertyCard } from '@SHARED/core/entities/sections/PropertyListingSection';
import type { PresenterLabel } from '@SHARED/presenters/LabelPresenter';
import type { Property, PropertyImage } from '@SHARED/core/entities/Property';
import type {
	GlobalPropertyCardConfig,
	WebsiteStyles
} from '@SHARED/core/entities/WebsiteConfig';
import type { CssSize } from '@SHARED/utils/helperTypes';

import { PropertyPresenter } from '@SHARED/presenters/PropertyPresenter';
import {
	DEFAULT_NUXT_IMAGE_PLACEHOLDER,
	getCSSColorVar
} from '@SHARED/utils/style';

import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import AcceptPartnershipButton from '@SHARED/components/atoms/AcceptPartnershipButton.vue';

import { NuxtLink } from '#components';

import GalleryIcon from '~icons/material-symbols/photo-library-outline-rounded';
import ExpandIcon from '~icons/material-symbols/expand-content';
import CalendarIcon from '~icons/mdi/calendar-outline';
import WhatsAppIcon from '~icons/mdi/whatsapp';
import ChevronRightIcon from '~icons/mdi/chevron-right';
import LetterIcon from '~icons/pilar/letter';

defineOptions({ name: 'PropertyCard' });

export type PropertyCardTrigger = 'card-body' | 'button';

type PropertyCardProps = PropertyCard & {
	optimizedWidth?: {
		sm: number;
		md: number;
		lg: number;
	};
	optimizedQuality?: number;
	redirectOnClick?: boolean;
	shouldShowGetInTouchButton?: boolean;
	acceptPartnershipRequestUrl?: string | null;
	showScheduleVisitButtons?: boolean;
	useCtaDirective?: boolean;
};

const props = withDefaults(defineProps<PropertyCardProps>(), {
	variant: 'default',
	height: '100%',
	source: 'base',
	optimizedWidth: () => ({
		sm: 500,
		md: 700,
		lg: 1000
	}),
	optimizedQuality: 80,
	redirectOnClick: true,
	brokerPhone: null,
	acceptPartnershipRequestUrl: null,
	showScheduleVisitButtons: true,
	useCtaDirective: false
});

type Emits = {
	(e: 'open-campaign-modal'): void;
	(e: 'open-gallery'): void;
	(e: 'view-property', property: Property, trigger: PropertyCardTrigger): void;
	(e: 'open-schedule-property-visit-modal'): void;
	(e: 'send-get-in-touch-message'): void;
};

const emit = defineEmits<Emits>();

const styles = useState<WebsiteStyles>('styles');
const propertyCardConfig =
	useState<GlobalPropertyCardConfig>('propertyCardConfig');

// TODO: A tag <a> não deve ter botões como filhos, temos que alinhar com o time de produto para decidir o que fazer
const component = computed<typeof NuxtLink | 'div'>(() =>
	props.redirectOnClick ? NuxtLink : 'div'
);

const isKollerVariant = computed<boolean>(() => props.variant === 'koller');

const isFullsizeVariant = computed<boolean>(() => props.variant === 'fullsize');

const isMinisiteVariant = computed<boolean>(() => props.variant === 'minisite');

const propertyImageSizes = computed<string>(() => {
	const { sm, md, lg } = props.optimizedWidth;

	if (isKollerVariant.value || isFullsizeVariant.value) {
		return `${sm}px md:${md}px lg:${lg}px`;
	}

	return `${lg}px`;
});

const desktopPropertyFeatureFontSize = computed<CssSize>(() => {
	if (isKollerVariant.value) return '1rem';

	return '0.875rem';
});

const items = computed<PresenterLabel[]>(() =>
	PropertyPresenter.getItemsInformation(
		['area', 'totalArea', 'bedrooms', 'suites', 'parkingSpots'],
		props.property,
		'simplified'
	)
);

const itemIconColor = computed<string>(() =>
	propertyCardConfig.value?.roomsIconsColor
		? getCSSColorVar(propertyCardConfig.value.roomsIconsColor)
		: '#000'
);

const thumbnail = computed<PropertyImage>(() =>
	PropertyPresenter.getThumbnail(props.property)
);

const $img = useImage();

const thumbnailBackgroundUrl = computed<string>(() => {
	// <!-- TODO: no futuro podemos usar o método $img.getSizes para otimizar mais a imagem, mas no momento a API desse método ainda é instável e pode mudar ou ser removida a qualquer momento -->
	const optimizedImageUrl = $img(thumbnail.value.watermarkUrl, {
		width: props.optimizedWidth.lg,
		format: 'webp',
		quality: props.optimizedQuality
	});

	return `url(${optimizedImageUrl})`;
});

const tags = computed<string[]>(() =>
	PropertyPresenter.getTags(props.property)
);

const propertyAddress = computed<string>(() =>
	PropertyPresenter.getAddressText(props.property)
);

const cardProps = computed<Record<string, any>>(() => {
	if (!props.redirectOnClick || props.variant === 'minisite') return {};

	return {
		to: {
			name: 'PropertyPage',
			params: {
				propertySlug: props.property.slug
			}
		}
	};
});

const pricing = computed<[string, string?]>(() => {
	const { askingPrice, rentPrice, ad } = props.property;

	if (ad.shouldHidePrices) return ['Valor sob consulta'];

	const askingPriceText = PropertyPresenter.getCurrencyInfoText(askingPrice);
	const rentPriceText = `${PropertyPresenter.getCurrencyInfoText(
		rentPrice
	)}/mês`;

	if (askingPrice && rentPrice) return [askingPriceText, rentPriceText];
	if (!askingPrice && rentPrice) return [rentPriceText];

	return [askingPriceText];
});

const getInTouchButtonConfig = computed(() => {
	if (!props.useCtaDirective) return {};

	return {
		'v-cta': {
			commercialId: props.property.commercialId,
			propertySource: props.source,
			action: 'talk-to-broker',
			trigger: 'property-card'
		}
	};
});

const scheduleVisitButtonConfig = computed(() => {
	if (!props.useCtaDirective) return {};

	return {
		'v-cta': {
			commercialId: props.property.commercialId,
			propertySource: props.source,
			action: 'schedule-visit',
			trigger: 'property-card'
		}
	};
});
</script>

<style lang="scss" scoped>
// ! TODO: refatorar para seguir as boas práticas do Sass, principalmente para evitar seletores com mais de 3 níveis de profundidade
.property-card {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	transition: all 300ms ease-in-out;

	&:hover {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

		.card-thumbnail {
			img {
				transform: scale(1.05);
			}

			.campaign-modal-button {
				opacity: 1;
			}
		}
	}

	.card-thumbnail,
	.card-content {
		position: relative;
	}

	.card-thumbnail {
		display: flex;
		flex-direction: column;
		transition: all 300ms ease-in-out;

		.tag-wrapper {
			left: 1rem;
			display: flex;
			gap: 0.5rem;

			.tag {
				background-color: v-bind(tagBackgroundColor);
				color: v-bind(tagTextColor);
				padding: 0.5rem 0.75rem;
				font-size: 0.75rem;
				letter-spacing: 0.025em;

				@include border-radius-size();
			}
		}

		img {
			width: 100%;
			object-fit: cover;
			height: 16rem;
			transition: transform 0.3s ease-in-out;
		}
	}

	.card-content {
		background-color: var(--white);
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 0.75rem;
		height: 100%;
		justify-content: space-between;

		@include screen-up(lg) {
			padding: 1rem;
		}

		.card-heading {
			display: flex;
			justify-content: space-between;
			gap: 1rem;

			.type-and-address {
				.address-text {
					font-size: 1rem;
					font-weight: 400;
				}
			}

			.commercial-id-text {
				font-weight: 400;
				font-size: 0.875rem;
				color: var(--darkgray);
			}
		}

		.pricing {
			display: flex;
			align-items: center;
			gap: 1.25rem;
			padding-top: 1rem;
			border: 0px solid var(--lightgray);
			border-top-width: 1px;

			.primary-price {
				font-size: 1.25rem;
				font-weight: 500;
			}

			.secondary-price {
				font-size: 1rem;
				font-weight: 500;
			}
		}

		.property-rooms {
			display: flex;
			align-items: center;
			gap: 1rem;

			.item {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				text-transform: lowercase;
				font-size: 0.875rem;
				text-wrap: nowrap;
				text-overflow: ellipsis;

				@include screen-up(lg) {
					font-size: v-bind(desktopPropertyFeatureFontSize);
				}

				.item-value {
					font-size: inherit;
					min-width: max-content;
				}

				.item-label {
					display: none;
					font-size: inherit;
					text-transform: lowercase;
				}

				svg {
					width: 1.5rem;
					height: 1.5rem;
					color: v-bind(itemIconColor);
					fill: v-bind(itemIconColor);
				}
			}
		}

		.type-and-address {
			display: flex;
			flex-direction: column;

			.property-type-text {
				font-size: 0.875rem;
				text-transform: uppercase;
			}

			.address {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 1rem;

				.address-text {
					font-size: 0.875rem;
					font-weight: 300;
				}

				span {
					text-transform: capitalize;
				}
			}
		}
	}

	&[data-variant='koller'] {
		.card-thumbnail {
			img {
				height: 10rem;

				@include screen-up(lg) {
					height: 21.75rem;
				}
			}

			.tag-wrapper {
				left: auto;
				right: 1rem;

				.tag {
					font-weight: 500;
					padding: 0.375rem 0.75rem;
				}
			}
		}

		.card-content {
			padding: 1rem 0 0 0;
			gap: 0.75rem;
			height: auto;
			z-index: 1;

			.card-heading {
				.type-and-address {
					.address-text {
						font-size: 1rem;
						font-weight: 400;
						line-height: 120%;

						@include screen-up(lg) {
							font-size: 1.5rem;
						}
					}
				}

				.commercial-id-text {
					display: none;
				}
			}

			.property-rooms {
				$propertyInfoGap: 1.25rem;
				$separatorWidth: 0.25rem;

				gap: $propertyInfoGap;

				& > :not([hidden]) ~ :not([hidden]) {
					position: relative;

					&::before {
						content: '';
						position: absolute;
						width: $separatorWidth;
						height: $separatorWidth;
						top: calc(50% - calc($separatorWidth / 2));
						left: calc(
							calc(-1 * calc($propertyInfoGap / 2)) - calc($separatorWidth / 2)
						);
						background-color: var(--gray);
					}
				}

				.item {
					.item-value,
					.item-label {
						font-size: 1rem;
						line-height: 110%;
						font-weight: 400;
						display: block;
					}

					.item-icon {
						display: none;
					}
				}
			}

			.pricing {
				display: none;
			}
		}
	}
}

.campaign-modal-button {
	transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	bottom: 1rem;
	right: 1rem;
	position: absolute;
	font-weight: 600;
	flex-direction: row-reverse;

	@include screen-up(lg) {
		opacity: 0;
	}

	svg {
		width: 1.25rem;
		fill: var(--white);
	}
}

.tag-wrapper {
	position: absolute;
	top: 1rem;
	display: flex;
	gap: 0.5rem;
	z-index: 1;

	.tag {
		background-color: v-bind(tagBackgroundColor);
		color: v-bind(tagTextColor);
	}
}

.fullsize-card {
	height: 30rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2rem;

	background-image: v-bind(thumbnailBackgroundUrl);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 300ms ease-in-out;

	.thumbnail-overlay {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		transition: background-position 300ms ease-in-out;
		background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.75));
		background-position: 0 0;
		background-size: 100% 125%;
	}

	&:hover {
		padding-bottom: 3rem;

		.thumbnail-overlay {
			background-position: 0 -100px;
		}

		.card-content .property-rooms {
			height: auto;

			& > div:nth-of-type(1n + 4) {
				opacity: 1;
			}
		}
	}

	.tag-wrapper {
		right: 1rem;

		.tag {
			padding: 0.375rem 0.5rem;
			font-size: 0.875rem;
			font-weight: 500;
		}
	}

	.card-content {
		display: flex;
		gap: 1rem;
		flex-direction: column;
		color: var(--white);
		line-height: 115%;
		height: 100%;
		justify-content: flex-end;
		z-index: 1;

		.type-and-address {
			font-weight: 400;
			font-size: 1.75rem;
			line-height: 125%;
		}

		.property-rooms {
			display: flex;
			flex-wrap: wrap;

			@include screen-up(lg) {
				height: 1.5lh;
			}

			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				text-transform: lowercase;
				font-size: 1.125rem;
				line-height: 150%;
				font-weight: 400;
				text-wrap: nowrap;

				&:not(:first-child)::before {
					content: '';
					display: inline-block;
					margin-left: 0.75rem;
					margin-right: 0.75rem;
					width: 4px;
					height: 4px;
					background-color: rgba(255, 255, 255, 0.4);
				}

				&:nth-of-type(1n + 4) {
					opacity: 0;
				}

				svg {
					display: none;
				}
			}
		}

		.pricing {
			display: flex;
			align-items: center;
			gap: 1.25rem;

			.primary-price {
				font-size: 1.375rem;
				font-weight: 500;
				line-height: 1.5rem;
			}

			.secondary-price {
				font-size: 1.25rem;
				font-weight: 500;
				line-height: 1.375rem;
			}
		}
	}
}

.minisite-card {
	height: 22.5rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem;
	overflow: hidden;
	transition: all 300ms ease-in-out;

	@include screen-up(md) {
		height: 25rem;
		padding: 1.75rem;
		cursor: pointer;

		&:hover {
			&::before {
				transform: scale(1.25);
			}

			.thumbnail-overlay {
				background-position: 0 -6.25rem;
			}

			.card-content {
				@include screen-up(md) {
					.property-contact-and-media-container,
					.property-rooms {
						max-height: 15rem;
						opacity: 1;
						transform: translateY(0);
					}

					.property-contact-and-media-container {
						overflow: visible;
					}

					.property-rooms {
						padding-top: 0.5rem;
						padding-bottom: 1.25rem;
					}
				}
			}
		}
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: v-bind(thumbnailBackgroundUrl);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		transition: transform 300ms ease-in-out;
	}

	.thumbnail-overlay {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		transition: background-position 300ms ease-in-out;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 35%,
			rgba(0, 0, 0, 0.75)
		);
		background-position: 0 0;
		background-size: 100% 125%;
	}

	.tag-wrapper {
		left: 1rem;

		.tag {
			padding: 0.25rem 0.375rem;
			font-size: 0.875rem;
			font-weight: 500;
		}
	}

	.card-content {
		display: flex;
		flex-direction: column;
		color: var(--white);
		line-height: 115%;
		height: 100%;
		justify-content: flex-end;
		z-index: 1;

		.card-heading {
			display: flex;
			flex-direction: column-reverse;
			gap: 0.75rem;

			.pricing {
				display: flex;
				align-items: center;
				gap: 1.25rem;

				.primary-price {
					font-size: 1.125rem;
					font-weight: 600;
					line-height: 100%;
				}

				.secondary-price {
					font-size: 1rem;
					font-weight: 600;
					line-height: 100%;

					@include screen-up(md) {
						font-size: 1.25rem;
					}
				}
			}

			.commercial-id-text {
				font-size: 0.875rem;
				font-weight: 400;
			}

			.type-and-address {
				display: flex;
				align-items: center;
				gap: 0.25rem;
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 1.25rem;

				@include screen-up(md) {
					font-size: 1.125rem;
					line-height: 1.375rem;
				}

				.separator-icon {
					align-items: center;
					justify-content: center;
					height: 1.25rem;
					width: 1.25rem;
				}
			}
		}

		.property-rooms {
			@include screen-up(md) {
				opacity: 0;
				max-height: 0;
				overflow: hidden;
				transition: all 0.24s ease-in-out;
				transform: translateY(100%);
			}
		}

		.property-contact-and-media-container {
			@include screen-up(md) {
				opacity: 0;
				max-height: 0;
				overflow: hidden;
				transition: all 0.3s ease-in-out;
				transform: translateY(100%);
			}
		}

		.property-rooms {
			display: flex;
			flex-wrap: wrap;
			margin-top: 0.75rem;

			.item-text {
				font-size: 0.875rem;
				font-weight: 400;
				line-height: normal;

				@include screen-up(md) {
					font-size: 1rem;
					font-weight: 600;
				}
			}

			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				text-transform: lowercase;
				font-size: 0.75rem;
				line-height: 150%;
				font-weight: 400;
				text-wrap: nowrap;
				transition: all 300ms ease-in-out;
				font-family: var(--default-font);

				@include screen-up(md) {
					font-size: 1rem;
				}

				&:not(:first-child)::before {
					content: '';
					display: inline-block;
					margin-left: 0.5rem;
					margin-right: 0.5rem;
					width: 1px;
					height: 0.8125rem;
					background-color: rgba(255, 255, 255, 0.4);
				}

				svg {
					display: none;
				}
			}
		}

		.property-contact-and-media-container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.buttons-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				gap: 0.75rem;
				width: 100%;

				@include screen-down(md) {
					position: absolute;
					top: 0;
					right: 0;
					z-index: 2;
					flex-direction: column;
					justify-content: flex-start;
					align-content: flex-end;
					padding: 1rem;
					gap: 0.75rem;

					.buttons-container {
						flex-direction: column;

						.view-property-info,
						.property-contact-button {
							display: flex;
							align-items: center;
							justify-content: center;
							min-height: 2.75rem;
							height: 2.75rem;
							min-width: 2.75rem;
							width: 2.75rem;
							padding: 0;
							border-radius: var(--pill-border-radius);

							svg {
								width: 1.5rem;
								height: 1.5rem;
							}

							.button-text {
								display: none;
							}
						}

						.expand-property-button {
							display: none;
						}
					}
				}
			}

			.buttons-container {
				display: flex;
				align-items: center;
				gap: 0.75rem;
				flex-wrap: wrap;

				.property-contact-button {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					height: 2.5rem;
					padding: 0.625rem 1rem;

					.button-text {
						text-wrap: nowrap;
						font-size: 0.875rem;
					}

					svg {
						width: 1.25rem;
						height: 1.25rem;
					}
				}

				.view-property-info {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 2.5rem;
					width: 2.5rem;
					background-color: var(--white);
					color: var(--black);
					transition: all 300ms ease-in-out;

					@include border-radius-size();

					.view-property-button-icon {
						width: 1.5rem;
						height: 1.5rem;
					}

					&:hover {
						background-color: var(--lightgray);

						.view-property-info-text {
							display: block;
						}
					}
				}

				.view-property-info-text {
					display: none;
					position: absolute;
					top: calc(100% + 0.25rem);
					padding: 1px 0.25rem;
					background-color: var(--darkgray);
					color: var(--white);
					text-wrap: nowrap;
					font-size: 0.75rem;
					font-weight: 500;
				}
			}
		}
	}
}
</style>
